import React from "react";

const Landing = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>App</h1>
      Collect feedback from your users
    </div>
  );
};

export default Landing;
